import './App.scss';
import logo from '../assets/logo.png';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import { Component, Fragment } from 'react';
import axios from 'axios';
import Questions from '../components/Questions/Questions';
import Contact from '../components/Contact/Contact';
import ValidationContext from '../context/validationContext';
import image from '../assets/image/Panorama13.jpg';
import Intro from '../components/Intro/Intro';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePage: 0,
			displayError: false,
			result: [],
			started: false,
			questionPages: [
				{
					title: 'Fragebogen zum Thema „Mitarbeiterwohnen“',
					pageComplete: false,
					questions: [
						{
							id: 'q_1',
							title: '',
							content: (
								<p>
									Bitte geben Sie den Namen Ihres
									Unternehmens/Ihrer Organisation/Institution
									an.
								</p>
							),
							possibleAnswers: [],
							givenAnswer: [],
						},
						{
							id: 'q_2',
							title: '',
							content: (
								<div>
									<p>
										<strong>
											1. Der Bedarf an Fach- und
											Führungskräften ist höher denn je.
											Verschiedene Studien, Diskussionen
											und die Erfahrung der Unternehmen
											zeigen, dass bei der
											Personalrekrutierung neben einem
											attraktiven Jobangebot auch die
											Frage der Wohnraumversorgung eine
											immer stärkere Rolle spielt.
										</strong>
									</p>
									<p>
										<strong>
											Wie schätzen Sie anhand Ihrer
											Personalentwicklung im Unternehmen
											den Bedarf pro Jahr an Wohnungen für
											Ihre Mitarbeitenden für die nächsten
											5 Jahre ein und wie groß sollte die
											Wohnung sein?
										</strong>
									</p>
									<p>Anzahl Wohnungen p.a.</p>
								</div>
							),
							type: 'number',
							possibleAnswers: ['Anzahl Wohnungen'],
							givenAnswer: [],
						},
						{
							id: 'q_3',
							title: '',
							content: (
								<div>
									<p>davon:</p>
									<p>überwiegend 1-Zimmer-Wohnungen</p>
								</div>
							),
							type: 'number',
							possibleAnswers: ['1-Zimmer'],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_4',
							title: '',
							content: <p>überwiegend 2-Zimmer-Wohnungen</p>,
							type: 'number',
							possibleAnswers: ['2-Zimmer'],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_5',
							title: '',
							content: <p>größer</p>,
							type: 'number',
							possibleAnswers: ['Größer'],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_6',
							title: 'Frage 2:',
							content: (
								<div>
									<p>
										<strong>
											2. Unsere Erfahrungen aus der
											Vergangenheit haben gezeigt, dass
											möblierte Apartments als temporäres
											Wohnangebot aufgrund der
											Flexibilität bevorzugt werden. Wie
											schätzen Sie den Bedarf an
											möblierten Apartments/unmöblierten
											Apartments für Ihre Mitarbeitenden
											ein?
										</strong>
									</p>
									<p>Möblierte Apartments:</p>
								</div>
							),
							type: 'radio',
							possibleAnswers: [
								{ text: 'notwendig', value: 'notwendig' },
								{ text: 'teils/teils', value: 'teils/teils' },
								{
									text: 'weniger notwendig',
									value: 'weniger notwendig',
								},
							],
							givenAnswer: [],
						},
						{
							id: 'q_7',
							title: '',
							content: <p>Unmöblierte Apartments:</p>,
							type: 'radio',
							possibleAnswers: [
								{ text: 'notwendig', value: 'notwendig' },
								{ text: 'teils/teils', value: 'teils/teils' },
								{
									text: 'weniger notwendig',
									value: 'weniger notwendig',
								},
							],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_8',
							title: '',
							content: (
								<div>
									<p>
										<strong>
											3. An welchen Standorten in Berlin
											sehen Sie den größten Personalbedarf
											bzw. wäre nach Ihrer Einschätzung
											ein Angebot notwendig?
										</strong>
										<br />
										<small>
											(Mehrfachantworten möglich)
										</small>
									</p>
								</div>
							),
							type: 'checkbox',
							possibleAnswers: [
								{
									text: 'Charlottenburg-Wilmersdorf',
									value: 'Charlottenburg-Wilmersdorf',
								},
								{
									text: 'Friedrichshain-Kreuzberg',
									value: 'Friedrichshain-Kreuzberg',
								},
								{
									text: 'Marzahn-Hellersdorf',
									value: 'Marzahn-Hellersdorf',
								},
								{ text: 'Neukölln', value: 'Neukölln' },
								{
									text: 'Steglitz-Zehlendorf',
									value: 'Steglitz-Zehlendorf',
								},
								{
									text: 'Tempelhof-Schöneberg',
									value: 'Tempelhof-Schöneberg',
								},
								{
									text: 'Treptow-Köpenick',
									value: 'Treptow-Köpenick',
								},
								{
									text: 'Reinickendorf',
									value: 'Reinickendorf',
								},
								{
									text: 'Mitte',
									value: 'Mitte',
								},
								{
									text: 'Pankow',
									value: 'Pankow',
								},
								{
									text: 'Lichtenberg',
									value: 'Lichtenberg',
								},
								{
									text: 'Spandau',
									value: 'Spandau',
								},
							],
							givenAnswer: [],
						},
						{
							id: 'q_9',
							title: '',
							content: (
								<div>
									<p>
										<strong>
											4. Auszubildende/Studierende, junge
											Berufsstarter, Fach- und
											Führungskräfte und Spezialisten
											suchten bisher für den Start in
											Berlin oftmals zunächst ein
											befristetes Wohnangebot, bevor sie
											sich für den passenden Standort
											entschieden haben. Wie schätzen Sie
											den Bedarf Ihres Unternehmens (oder
											Ihrer Mitarbeitenden) zukünftig ein
											– werden eher befristete (bis max.
											12 Monate) oder unbefristete
											Wohnangebote benötigt?
										</strong>
									</p>
								</div>
							),
							type: 'radio',
							possibleAnswers: [
								{
									value: 'befristet',
									text: 'eher Angebote für befristetes Wohnen (bis max. 12 Monate)',
								},
								{
									value: 'unbefristet',
									text: 'eher Angebote für unbefristetes Wohnen (länger als 12 Monate)',
								},
							],
							givenAnswer: [],
						},
						{
							id: 'q_10',
							title: '',
							content: (
								<div>
									<p>
										<strong>
											5. Es gibt verschiedene
											Möglichkeiten, Mitarbeitenden
											Angebote an Wohnraum zu
											unterbreiten.
										</strong>
										<br />
										Welche Möglichkeiten kommen für Ihr
										Unternehmen in Betracht?
										<br />
										<small>
											(Mehrfachantworten möglich)
										</small>
									</p>
								</div>
							),
							type: 'checkbox',
							possibleAnswers: [
								{
									value: 'direkt',
									text: 'direkt: Ihr Unternehmen tritt durch direkte Anmietung und entsprechende Weitervermietung bzw. Überlassung an Ihre Mitarbeitenden in Erscheinung – d. h. Sie agieren selbst als Vermieterin bzw. Vermieter.',
								},
								{
									value: 'indirekt',
									text: 'indirekt: Ihr Unternehmen sichert sich über Rahmenvereinbarungen/Belegungsrechte etc. Kontingente. Die Vermietung/Betreuung selbst erfolgt außerhalb Ihrer Unternehmung – durch die berlinovo.',
								},
								{
									value: 'indikativ',
									text: 'indikativ: Ihr Unternehmen erhält über eine Plattform (z. B. Website, App) Zugang zu tagesaktuellen Angeboten. Ihre Mitarbeitenden erhalten einen exklusiven Zugriff und können selbst direkt mieten.',
								},
							],
							givenAnswer: [],
						},
						{
							id: 'q_11',
							title: '',
							content: (
								<div>
									<p>
										<strong>
											6. Wie schätzen Sie den Bedarf an
											zusätzlichen
											Serviceleistungen/Ausstattungspaketen
											ein?
										</strong>
									</p>
									<p>Reinigungsservice</p>
								</div>
							),
							type: 'radio',
							possibleAnswers: [
								{ text: 'wichtig', value: 'wichtig' },
								{
									text: 'weniger wichtig',
									value: 'weniger wichtig',
								},
							],
							givenAnswer: [],
						},
						{
							id: 'q_12',
							title: '',
							content: <p>Wäschewechsel</p>,
							type: 'radio',
							possibleAnswers: [
								{ text: 'wichtig', value: 'wichtig' },
								{
									text: 'weniger wichtig',
									value: 'weniger wichtig',
								},
							],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_13',
							title: '',
							content: <p>Hausratausstattung</p>,
							type: 'radio',
							possibleAnswers: [
								{ text: 'wichtig', value: 'wichtig' },
								{
									text: 'weniger wichtig',
									value: 'weniger wichtig',
								},
							],
							givenAnswer: [],
							subquestion: true,
						},
						{
							id: 'q_14',
							title: '',
							content: (
								<p>
									<strong>
										Bitte geben Sie gerne noch den
										Ansprechpartner/die Ansprechpartnerin
										zum Thema „Mitarbeiterwohnen“ in Ihrem
										Hause an.
									</strong>
								</p>
							),
							type: 'text',
							possibleAnswers: [
								{ text: 'wichtig', value: 'wichtig' },
								{
									text: 'weniger wichtig',
									value: 'weniger wichtig',
								},
							],
							givenAnswer: [],
						},
					],
				},
			],
		};
	}

	inputChangeHandler = (event, questionId) => {
		const questionPages = [...this.state.questionPages];
		const activePage = questionPages[this.state.activePage];
		const questionIndex = activePage.questions.findIndex((q) => {
			return q.id === questionId;
		});
		const question = activePage.questions[questionIndex];

		if (event.target.type === 'checkbox') {
			if (event.target.checked) {
				question.givenAnswer.push(event.target.value);
			} else {
				let index = question.givenAnswer.indexOf(event.target.value);
				question.givenAnswer.splice(index, 1);
			}
		} else if (event.target.type === 'number') {
			question.givenAnswer = [
				question.possibleAnswers[0],
				event.target.value,
			];
		} else {
			question.givenAnswer = [event.target.value];
		}

		const questions = [
			...this.state.questionPages[this.state.activePage].questions,
		];
		questions[questionIndex] = question;

		let pageComplete =
			activePage.questions.findIndex((q) => {
				return (
					q.givenAnswer.length === 0 || q.givenAnswer[0].length === 0
				);
			}) === -1;

		activePage.pageComplete = pageComplete;
		activePage.questions = questions;
		this.setState({
			questionPages: questionPages,
		});
	};

	pageChangeHandler = (page) => {
		let unansweredPage = this.state.questionPages.findIndex((qp) => {
			return qp.pageComplete === false;
		});
		if (
			(this.state.questionPages[this.state.activePage].pageComplete ||
				page < this.state.activePage) &&
			page < this.state.questionPages.length &&
			page >= 0 &&
			(page <= unansweredPage || unansweredPage === -1)
		) {
			this.setState({ activePage: page, displayError: false });
		} else {
			this.setState(
				{ activePage: unansweredPage, displayError: true },
				() => {
					document.querySelector('.error-msg').scrollIntoView();
				}
			);
		}
	};

	submitAnswerHandler = (e) => {
		e.preventDefault();
		const surveyResult = [];

		// if (this.state.questionPages[this.state.activePage].pageComplete) {
		this.state.questionPages.forEach((qp) => {
			qp.questions.forEach((q) =>
				surveyResult.push({ question: q.id, answer: q.givenAnswer })
			);
		});
		axios.post('/ajax.php', { results: surveyResult }).then((response) => {
			console.log(response.data);
		});
		this.setState({ result: surveyResult, finished: true });
		// } else {
		// 	this.setState({ displayError: true }, () => {
		// 		document
		// 			.querySelector('.error-msg')
		// 			.scrollIntoView({ block: 'end' });
		// 	});
		// }
	};

	startClickHandler = (e) => {
		document.querySelector('.Hero').scrollIntoView();
		this.setState({ started: true });
	};

	render() {
		return (
			<div className='App'>
				<header className='App-header'>
					<Header logo={logo}></Header>
				</header>
				<main className='App-main'>
					<Hero image={image}>Umfrage</Hero>
					<ValidationContext.Provider
						value={{
							displayError: this.state.displayError,
							submit: this.submitAnswerHandler,
						}}
					>
						{this.state.started ? (
							this.state.finished ? (
								<Fragment>
									<div className='container'>
										<h2>
											Wir bedanken uns bei Ihnen, dass Sie
											sich die Zeit genommen haben, das
											zukünftige Angebot
											„Mitarbeiterwohnen für
											Landesunternehmen“ der berlinovo
											mitzugestalten.
										</h2>
									</div>
									<div className='container'>
										<h2 className='meta'>
											Bei Fragen wenden Sie sich bitte an
											unseren Kontakt
										</h2>
										<Contact />
									</div>
								</Fragment>
							) : (
								<Fragment>
									<Questions
										pages={this.state.questionPages.length}
										questions={
											this.state.questionPages[
												this.state.activePage
											].questions
										}
										changed={this.inputChangeHandler}
										activePage={this.state.activePage}
										pageChange={this.pageChangeHandler}
										title={
											this.state.questionPages[
												this.state.activePage
											].title
										}
									/>
									<div className='container'>
										<h2 className='meta'>
											Bei Fragen wenden Sie sich bitte an
											unseren Kontakt
										</h2>
										<Contact />
									</div>
								</Fragment>
							)
						) : (
							<Intro click={this.startClickHandler} />
						)}
					</ValidationContext.Provider>
				</main>
				<footer className='App-footer'></footer>
			</div>
		);
	}
}

export default App;
